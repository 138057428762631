/* global require */

'use strict';

// dependencies
var DOM = require('./dom');

/**
 * Forms helper functions.
 *
 * @author Sebastian Prein <basti@gridonic.ch>
 * @return {Object}
 */
var Forms = (function() {

    /**
     * Submitting state class.
     *
     * @type {String}
     */
    var className = 'submitting';

    /**
     * Aborts the submitting state.
     *
     * @param {Element} form Form element.
     */
    var abort = function(form) {
        var submit = DOM.select('.submit', form);

        // add state class
        form.classList.remove(className);

        // disable submit button
        DOM.attr(submit, 'disabled', null);
    };

    /**
     * Adds the submitting state to a given form.
     *
     * @param {Element} form Form element.
     */
    var submit = function(form) {
        var submit = DOM.select('.submit', form);
        var keyUpFn = function(e) {

            // not escape key
            if (e.keyCode !== 27) {
                return;
            }

            // abort submitting state
            abort(form);

            // remove event listener
            DOM.off(window, e.type, keyUpFn);

            // stop any page loads
            try { window.stop(); }
            catch(e) { document.execCommand('Stop'); }
        };

        // add state class
        form.classList.add(className);

        // disable submit button
        if (submit) {
            DOM.attr(submit, 'disabled', '');
        }

        // escape should abort submitting state
        DOM.on(window, 'keyup', keyUpFn);
    };

    return {
        abort: abort,
        submit: submit
    };
})();

module.exports = Forms;
