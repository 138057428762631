/* global require */

'use strict';

// dependencies
var Library = {
    Bookmarks: require('../lib/bookmarks'),
    DOM: require('../lib/dom'),
    jQuery: require("./../../../bower_components/jquery/dist/jquery.js")
};

/**
 * Bookmarks module.
 *
 * @author Sebastian Prein <basti@gridonic.ch>
 * @return {Bookmarks}
 */
var Bookmarks = function () {

    // shortcuts
    var self = this;
    var DOM = Library.DOM;

    // public variables
    self.version = '0.1.0';

    // private variables
    var bookmarks;

    // private methods
    var fetchContainer;
    var fetchTemplate;
    var fillTemplate;
    var onAdd;
    var onBookmarkButton;
    var onBookmarkRemoveButton;
    var onRemove;
    var onShowBookmarks;

    /**
     * Public: Initialization logic of Bookmarks module.
     */
    self.init = function init() {

        var container = fetchContainer('[data-bookmarks]');
        var template = fetchTemplate('[data-bookmark]');

        if (container === null || template === null) {
            return;
        }

        // wipe container by removing all nodes including text nodes
        while (container.hasChildNodes()) {
            container.removeChild(container.lastChild);
        }

        // get instance of bookmarks library
        bookmarks = new Library.Bookmarks({
            container: container,
            template: template,
            onAddCallback: onAdd,
            onRemoveCallback: onRemove
        });

        // attach logic to bookmark buttons
        DOM.on('[data-bookmarkable]', window.App.c.clickEvent, onBookmarkButton);
        DOM.on('[data-bookmarks-counter]', window.App.c.clickEvent, onShowBookmarks);
    };

    /**
     * Tries to grab the HTML code for a bookmark.
     *
     * @param {String|Element} templateElement The template Element or selector.
     * @return {String}
     */
    fetchTemplate = function (templateElement) {

        // we just have a selector
        if (typeof templateElement === 'string') {
            templateElement = DOM.select(templateElement);
        }

        // template not found
        if (templateElement === null) {
            console.warn('Bookmarks template not found.');

            return '';
        }

        // create temporary element for fetching whole template content
        var tmp = document.createElement('div');

        // append template element
        tmp.appendChild(templateElement);

        // use template as html string
        return tmp.innerHTML.trim();
    };

    /**
     * Tries to grab the HTML container element for all of the bookmarks.
     *
     * @param {String|Element} containerElement The container Element or selector.
     * @return {String}
     */
    fetchContainer = function (containerElement) {

        // we just have a selector
        if (typeof containerElement === 'string') {
            containerElement = DOM.select(containerElement);
        }

        // template not found
        if (containerElement === null) {
            console.warn('Bookmarks container not found.');
        }

        return containerElement;
    };

    /**
     * Creates a DOM Element from the template and fills it with the given data.
     *
     * @param {String} template Template HTML.
     * @param {Object} data Bookmark data.
     * @return {Element}
     */
    fillTemplate = function (template, data) {

        // create temporary element
        var tmp = document.createElement('div');

        // inject template
        tmp.innerHTML = template;

        // dump temporary element
        template = tmp.firstChild;

        // apply data to template
        for (var key in data) {

            // get element according to the data-attribute
            var el = DOM.select('[data-bookmark-' + key + ']', template);

            // no element with that data attribute
            if (el === null) {
                continue;
            }

            // get value
            var value = data[key];

            // is image
            if (typeof value === 'string' && value.substr(0, 3) === 'src') {
                var src = value.substr(4);
                var noimage = DOM.select('[data-bookmark-thumbnail-noimage]', template);

                // we have an image path, insert it and remove noimage placeholder
                if (src) {
                    DOM.attr(el, 'src', value.substr(4));
                    DOM.remove(noimage);
                } else {
                    DOM.remove(el);
                }

                // is link
            } else if (typeof value === 'string' && value.substr(0, 4) === 'href') {
                DOM.attr(el, 'href', value.substr(5));

                // is html
            } else if (typeof value === 'string' && value.substr(0, 4) === 'html') {
                el.innerHTML = value.substr(5);

                // just insert value as text node
            } else {
                el.textContent = value;
            }
        }

        // get remove button
        var removeButton = DOM.select('[data-bookmark-remove]', template);

        // attach logic to remove buttons
        if (removeButton) {
            DOM.on(removeButton, window.App.c.clickEvent, onBookmarkRemoveButton);
        }

        return template;
    };

    /**
     * Bookmark remove button was hit.
     *
     * @param {Event} e Original event.
     */
    onBookmarkRemoveButton = function (e) {

        // prevent default action
        e.preventDefault();

        // get closest bookmark
        var bookmark = DOM.closest(e.target, '[data-bookmark]');

        // bookmark not found, pretty stange. button misplaced?
        if (bookmark === null) {
            return;
        }

        // get bookmark index
        var index = DOM.data(bookmark, 'bookmark');

        // remove it
        bookmarks.remove(index);
    };

    /**
     * When an item is being added also push it into the container.
     *
     * @param {String} index Bookmark index.
     * @param {Object} data Bookmark data.
     */
    onAdd = function (index, data) {
        var container = this.getContainer();
        var template = this.getTemplate();
        var counter = DOM.select('[data-bookmarks-counter]');
        var indicator = DOM.select('[data-bookmark-id="' + index + '"]');

        // fill template with data
        template = fillTemplate(template, data);

        // add index in template
        DOM.data(template, 'bookmark', index);

        // get all google tag manager attributes
        var gtmIds = DOM.selectAll('[data-gtm-id]', template);

        if (gtmIds) {
            for (var i = 0; i < gtmIds.length; i++) {

                // append index to google tag manager id
                DOM.data(gtmIds[i], 'gtm-id', [DOM.data(gtmIds[i], 'gtm-id'), index].join('-'));
            }
        }

        // inject item into container
        container.appendChild(template);

        // add active state from bookmark button
        if (indicator) {
            indicator.classList.add('active');
        }

        // update counter
        if (counter) {
            counter.textContent = this.size();
        }
    };

    /**
     * When an item is being removed also remove it from the container.
     *
     * @param {String} index Bookmark index.
     */
    onRemove = function (index) {
        var bookmark = DOM.select('[data-bookmark="' + index + '"]');
        var counter = DOM.select('[data-bookmarks-counter]');
        var indicator = DOM.select('[data-bookmark-id="' + index + '"]');

        // remove bookmark from DOM
        DOM.remove(bookmark);

        // remove active state from bookmark button
        if (indicator) {
            indicator.classList.remove('active');
        }

        // update counter
        if (counter) {
            counter.textContent = this.size();
        }
    };

    /**
     * Add to bookmarks if not already in it else remove it.
     *
     * @param {Event} e Original event.
     */
    onBookmarkButton = function (e) {
        var self = this;
        var index;
        var data;

        index = DOM.data(self, 'bookmark-id');
        data = JSON.parse(DOM.data(self, 'bookmark-data'));

        // we don't have any data to bookmark
        if (index === null || data === null) {
            return;
        }

        // avoid default action
        e.preventDefault();
        e.stopPropagation();

        bookmarks.swap(index, data);
    };

    onShowBookmarks = function (e) {
        var self = this;
        var $ = Library.jQuery;
        var $el = $(e.target);

        if ($el.parents('li').hasClass('open')) {
            $el.parents('.main-header').css('position', '');
        } else {
            $el.parents('.main-header').css('position', 'relative');
        }
    };

    return this;
};

module.exports = Bookmarks;
