/* global require */

'use strict';

// dependencies
var fastClick = require("./../../../bower_components/fastclick/lib/fastclick.js");
var Flickity = require('flickity');
var DOM = require('../lib/dom.js');
var $ = require("./../../../bower_components/jquery/dist/jquery.js");
var GMaps = require('../lib/gmaps');

require('matchHeighter');

var UI = function UI() {

    // Methods
    var initClassToggles;
    var initMatchHeight;
    var initCurtainButtons;
    var initTabs;
    var initSurroundingMap;
    //var initDescriptionMap;
    var initGallery;
    var initDetailContentHeight;
    var initStretchers;
    var initDetailPopIn;
    var initSearchResultsPopIn;

    /**
     * Provides a generous class toggling functionality that can be controlled
     * with data attributes.
     */
    initClassToggles = function () {
        DOM.on('[data-toggle-class]', 'click', function (e) {
            var self = this;
            var className = DOM.data(self, 'toggle-class');
            var targetSelector = DOM.attr(self, 'href') || DOM.data(self, 'toggle-for');
            var targets = [];
            var match;
            var el;
            var toggler = null;

            // Check if target should always be data, perhaps if you use a link as trigger element.
            if (DOM.data(self, 'toggle-selector') === 'data') {
                targetSelector = DOM.data(self, 'toggle-for');
            }

            // Target is parent element
            if (targetSelector === 'parent') {
                targets.push(self.parentElement);

                // Target should be one of the ancestors
            } else if (typeof targetSelector === 'string' && (match = targetSelector.match(/closest:(.*)/))) {
                targets = targets.concat(DOM.closest(self, match[1]) || []);

                // Use target provided by attributes
            } else if (targetSelector) {
                var list = targetSelector.split(',');

                for (var i = 0; i < list.length; i++) {
                    targets = targets.concat(Array.prototype.slice.call(DOM.selectAll(list[i]) || []));
                }

                // Check if the click is opening or closing the target.
                if (DOM.data(self, 'toggle-autoclose')) {
                    toggler = $(targets[0]).hasClass(className);
                }

                // Target element is itself
            } else {
                targets.push(self);
            }

            // Toggle class for all targets
            for (var j = 0; j < targets.length; j++) {
                el = targets[j];

                // Prevent default behaviour
                //e.preventDefault(); // TODO: this should not happen for real links. see WX-296

                // Toggle class
                el.classList.toggle(className);

                if (toggler === null) {
                    // Has autoclose, so close it when clicked outside of it
                    if (DOM.data(self, 'toggle-autoclose') && el.classList.contains(className)) {
                        DOM.ifClickedOutside(el, className);
                    }
                } else if (!toggler) {
                    // WX-228 prevent closing element during the opening click event.
                    if (DOM.data(self, 'toggle-autoclose')) {
                        e.stopPropagation(); // stop the running click event before trigger click-outside-event
                        DOM.ifClickedOutside(el, className);
                    }
                }
            }
        });
    };

    /**
     * Initializes equal heights of children in parent containers.
     * Parent container define the data-equal attribute. This is what is
     * used to target the children, whose heights should be equalized.
     */
    initMatchHeight = function () {
        var $parentContainers = $('[data-equal]');
        var matchHeight = function () {
            $parentContainers.each(function () {
                var $container = $(this);
                var $matchHeightTargets = $container.find($container.data('equal'));

                if ($matchHeightTargets.length > 0) {
                    $matchHeightTargets.matchHeight();
                }
            });
        };

        matchHeight();
    };

    /**
     * Initializes the curtain buttons.
     */
    initCurtainButtons = function () {
        DOM.on('[data-button-curtain]', 'click', function () {
            this.classList.add('show');
        });
    };

    /**
     * Initializes tabs.
     */
    initTabs = function () {
        var className = 'active';
        var tabs = DOM.selectAll('[data-tabs]');
        var tabContents = DOM.selectAll('[data-tab-content]');
        var fn = function (e) {
            var tabs = DOM.selectAll('[data-tab]', e.currentTarget);
            var tab = DOM.closest(e.target, '[data-tab]');

            if (tab === null || tab.classList.contains(className)) {
                return;
            }

            for (var i = 0; i < tabContents.length; i++) {
                tabContents[i].classList.remove(className);
            }

            for (i = 0; i < tabs.length; i++) {
                if (tab !== tabs[i]) {
                    tabs[i].classList.remove(className);
                    continue;
                }

                tabs[i].classList.add(className);

                if (tabContents[i] !== undefined) {
                    tabContents[i].classList.add(className);
                }
            }
        };

        if (tabs) {
            for (var i = 0; i < tabs.length; i++) {
                DOM.on(tabs[i], 'click touchstart', fn);
            }
        }
    };

    /**
     * Initializes the (Google) map when viewing the surroundings tab.
     */
    initSurroundingMap = function () {
        var surroundingTab = DOM.select('[data-surroundings]');
        var fn = function (e) {

            //window.App.fn.initMap();
            window.App.fn.initCityFacts();

            // Init only once
            DOM.off(e.currentTarget, 'click touchstart', fn);
        };

        DOM.on(surroundingTab, 'click touchstart', fn);
    };

    /**
     * Initializes the (Google) map when viewing the surroundings tab.
     */
    /*initDescriptionMap = function () {
        setTimeout(function () {
            // map container
            var container = document.getElementById('map_description');
            // use location provided by container
            var location = JSON.parse(DOM.data(container, 'location'));

            var map = new window.google.maps.Map(container, {
                center: {
                    lat: parseFloat(location.lat),
                    lng: parseFloat(location.lng)
                },
                zoom: 17,
                scrollwheel: true,
                zoomControl: true
            });

            // create marker
            var marker = new window.google.maps.Marker({
                position: map.getCenter(),
                icon: DOM.data(container, 'icon'),
                persistent: true,
                map: map
            });
        }, 200);
    };*/

    /**
     * Initializes the galleries on detail pages.
     */
    initGallery = function () {
        var galleryImages = DOM.select('[data-gallery-images]');
        var galleryThumbnails = DOM.selectAll('[data-gallery-thumbnail]');
        var galleryLightbox = DOM.select('[data-gallery-lightbox]');
        var syncFn = function (target) {
            if (target.selectedIndex !== this.selectedIndex) {
                target.select(this.selectedIndex);
            }
        };

        // No gallery or empty gallery
        if (galleryImages === null || galleryImages.children.length < 1) {
            return;
        }

        // Init flickity
        var gallery = new Flickity(galleryImages, {
            setGallerySize: false,
            prevNextButtons: false,
            lazyLoad: 2
        });

        // Use thumbnails as navigation for flickity
        DOM.on(galleryThumbnails, 'click', function (e) {
            var index = Array.prototype.indexOf.call(e.currentTarget.parentElement.children, e.currentTarget);

            gallery.select(index);
        });

        // create lightbox for gallery images
        if (galleryLightbox === null) {
            return;
        }

        // grab all lightbox images
        var galleryLightboxImages = DOM.selectAll('img', galleryLightbox);
        var galleryLightboxItems = DOM.selectAll('[data-gallery-lightbox-items]', galleryLightbox);

        // add to each image current viewport size
        for (var i = 0; i < galleryLightboxImages.length; i++) {
            var image = galleryLightboxImages[i];
            var src = DOM.data(image, 'flickity-lazyload');
            var width = window.innerWidth || 1280;
            var height = window.innerHeight || 720;

            DOM.data(image, 'flickity-lazyload', src + '/s/' + width + '/' + height);
        }

        var lightbox = new Flickity(galleryLightbox, {
            pageDots: false,
            lazyLoad: 2,
            setGallerySize: false,
            prevNextButtons: false
        });

        // pretty lame, but we set the max height on every first child for
        // every lightbox item to avoid images being too high
        var setMaxHeightForLightboxItems = function () {
            for (i = 0; i < galleryLightboxItems.length; i++) {
                var item = galleryLightboxItems[i];

                item.firstElementChild.style.maxHeight = (item.offsetHeight - parseInt(
                    window.getComputedStyle(item).getPropertyValue('padding-top'),
                    10
                ) * 2) + 'px';
            }
        };

        DOM.on(window, 'resize load', setMaxHeightForLightboxItems);
        DOM.on('#galleryNext', 'click', gallery.next.bind(gallery));
        DOM.on('#galleryPrevious', 'click', gallery.previous.bind(gallery));
        DOM.on('#lightboxNext', 'click', lightbox.next.bind(lightbox));
        DOM.on('#lightboxPrevious', 'click', lightbox.previous.bind(lightbox));

        // sync gallery images with lightbox
        gallery.on('cellSelect', syncFn.bind(gallery, lightbox));
        lightbox.on('cellSelect', syncFn.bind(lightbox, gallery));
    };

    /**
     * We need to dynamically set the min-height of the detail content elements,
     * because of the fcked up design requirements.
     */
    initDetailContentHeight = function () {
        var gallery = DOM.select('[data-gallery]');
        var detailContents = DOM.selectAll('[data-tab-content]');
        var updateMinHeight = function (e) {
            var height = gallery.getBoundingClientRect().height + 'px';

            for (var i = 0; i < detailContents.length; i++) {
                detailContents[i].style.minHeight = height;
            }
        };

        // only set min height if gallery is given
        if (gallery === null || detailContents === null) {
            return;
        }

        DOM.on(window, 'resize load', updateMinHeight);
    };

    /**
     * Stretchers are UI elements that stretch themself once they have been
     * focused and shrink when they lose it.
     */
    initStretchers = function () {
        var stretchers = DOM.selectAll('[data-stretcher]');
        var stretcher;
        var spacer;
        var focused;

        if (stretchers === null) {
            return;
        }

        var getWidth = function (el) {
            return parseInt(window.getComputedStyle(el).getPropertyValue('min-width'), 10);
        };

        var onBlur = function (e) {
            stretcher.style.width = getWidth(stretcher) + 'px';
            focused = false;
        };

        var onFocus = function (e) {
            stretcher.style.width = '100%';
            focused = true;
        };

        var onResize = function (e) {
            if (focused !== true) {
                spacer.style.width = stretcher.style.width = getWidth(stretcher) + 'px';
            }
        };

        for (var i = 0; i < stretchers.length; i++) {
            stretcher = stretchers[i];
            var trigger = DOM.select('[data-stretcher-trigger]', stretcher);

            if (trigger === null) {
                continue;
            }

            // we'll need a space occupying element, because the stretcher won't do it
            spacer = document.createElement('span');

            spacer.classList.add('stretcher-spacer');

            stretcher.parentElement.insertBefore(spacer, stretcher);

            DOM.on(window, 'resize load', onResize);
            DOM.on(trigger, 'focus', onFocus);
            DOM.on(trigger, 'blur', onBlur);
        }
    };

    /**
     * Enables PopIn functionality on detail view.
     */
    initDetailPopIn = function initDetailPopIn() {
        var popInTimeOut;
        var $body = $('body');

        // this popin is only going to be on detail view
        if ($body.hasClass('detail') === false) {
            return;
        }

        var $popin = $('[data-popin]');

        // no popin found
        if ($popin.length < 1) {
            return;
        }

        // popin hide triggers
        var $hideTriggers = $('[data-form-contact] input, [data-form-contact] textarea, [data-popin-button]');

        // hide popin if trigger was clicked
        $hideTriggers.on('click', function () {
            $popin.removeClass('open');

            if (popInTimeOut) {
                popInTimeOut = clearTimeout(popInTimeOut);
            }
        });

        // show popin after 15 secs
        popInTimeOut = setTimeout(function showPopIn() {
            $popin.addClass('open');
        }, 1000 * 15);
    };

    /**
     * Enables PopIn functionality on search results view.
     */
    initSearchResultsPopIn = function initSearchResultsPopIn() {
        var $body = $('body');
        var storageKey = 'dontShowSearchResultsPopin';

        // this popin is only going to be on search results
        if ($body.hasClass('search-results') === false) {
            return;
        }

        var $popin = $('[data-popin]');

        // no popin found
        if ($popin.length < 1) {
            return;
        }

        // user already knows about search subscription
        if (window.localStorage.getItem(storageKey)) {
            $popin.removeClass('open');
        } else {
            $popin.addClass('open');
        }

        // popin hide triggers
        var $hideTriggers = $('[data-hide-popin]');

        // if the user clicks the hide popin button, we are not going to
        // show the popin again until he clears the session data
        $hideTriggers.on('click', function () {
            $popin.removeClass('open');
            window.localStorage.setItem(storageKey, true);
        });
    };

    /**
     * Initialize the UI.
     *
     * @return boolean
     */
    this.init = function init() {

        fastClick(document.body);
        initClassToggles();
        initMatchHeight();
        initCurtainButtons();
        initTabs();
        initSurroundingMap();
       // initDescriptionMap();
        initGallery();
        initDetailContentHeight();
        initStretchers();
        initDetailPopIn();
        initSearchResultsPopIn();

        return true;
    };

    return this;
};

module.exports = UI;
