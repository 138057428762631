/* global TATM, require */

'use strict';

// Dependencies
var $ = require("./../../../bower_components/jquery/dist/jquery.js");

// The ad-server of tamedia
var Tamedia = function Tamedia() {

    var getBoxInfo = function getBoxInfo() {
        return window.appConfig.adBoxes || [];
    };

    var getRentPriceRange = function getRentPriceRange(price) {
        if (price < 1500) { return '0-1499'; }
        else if (price < 2000) { return '1500-1999'; }
        else if (price < 2500) { return '2000-2499'; }
        else if (price < 3000) { return '2500-2999'; }
        else if (price < 3500) { return '3000-3499'; }
        else if (price < 4000) { return '3500-3999'; }
        else if (price < 4500) { return '4000-4499'; }
        else if (price < 5000) { return '4500-4999'; }
        else if (price < 10000) { return '5000-9999'; }
        else { return 'more_9999'; }
    };

    var getBuyPriceRange = function getBuyPriceRange(price) {
        if (price < 150000) { return '0-149999'; }
        else if (price < 300000) { return '150000-299999'; }
        else if (price < 500000) { return '300000-499999'; }
        else if (price < 750000) { return '500000-749999'; }
        else if (price < 1000000) { return '750000-999999'; }
        else if (price < 1250000) { return '1000000-1249999'; }
        else if (price < 1500000) { return '1250000-1499999'; }
        else if (price < 2000000) { return '1500000-1999999'; }
        else if (price < 3000000) { return '2000000-2999999'; }
        else if (price < 6000000) { return '3000000-5999999'; }
        else if (price < 30000000) { return '6000000-29999999'; }
        else { return 'more_29999999'; }
    };

    var getLivingAreaRange = function getLivingAreaRange(area) {
        if (area < 50) { return '10-49'; }
        else if (area < 70) { return '50-69'; }
        else if (area < 90) { return '70-89'; }
        else if (area < 120) { return '90-119'; }
        else if (area < 150) { return '120-149'; }
        else if (area < 200) { return '150-199'; }
        else { return 'more_199'; }
    };

    var getTargetObject = function getTargetObject(adInfo) {

        var winWidth = window.innerWidth;
        var winHeight = window.innerHeight;
        var targetObject = adInfo.pageObject;

        // Add in each case
        /*jshint camelcase: false */

        // Add on PDP (Product Detail Page)
        if (adInfo.pagePath === 'PDP') {
            targetObject.cl_kvrentprice_ran = null;
            targetObject.cl_buyprice_ran = null;
            targetObject.cl_livingarea_ran = null;

            if (targetObject.cl_kvrentprice) {
                var price = targetObject.cl_kvrentprice;
                targetObject.cl_kvrentprice_ran = getRentPriceRange(price);
            }

            if (targetObject.cl_kvbuyprice) {
                var buyprice = targetObject.cl_kvbuyprice;
                targetObject.cl_buyprice_ran = getBuyPriceRange(buyprice);
            }

            if (targetObject.cl_kvarealiving) {
                var area = targetObject.cl_kvarealiving;
                targetObject.cl_livingarea_ran = getLivingAreaRange(area);
            }
        }

        return targetObject;
    };

    var initTamediaBoxes = function initTamediaBoxes() {
        var boxInfo = getBoxInfo();
        var baseAdInfo = boxInfo[0];

        if (typeof baseAdInfo === 'undefined') {
            return;
        }

        TATM.adUnitFirst = true;

        var targetObject = getTargetObject(baseAdInfo);

        TATM.init(
            baseAdInfo.pageName,
            baseAdInfo.pagePath,
            targetObject,
            baseAdInfo.headerText,
            baseAdInfo.websiteContentWidth
        );

        // No sticky ads on our page
        TATM.blockSticky = true;

        $.each(boxInfo, function (index, box) {
            var settings = {
                    adUnitName: box.adUnitName,
                    targetContainerId: box.targetContainerId,
                    callback: null,
                    adHeader: false
            };

            TATM.initAdUnits([settings]);
        });

        TATM.displayAds();
    };

    this.init = function init() {

        if (typeof(TATM) === 'undefined') {
            return null;
        }

        initTamediaBoxes();

        return this;
    };

    return this;
};

module.exports = Tamedia;
