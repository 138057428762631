/* global require */

'use strict';

// Dependencies
var $ = require("./../../../bower_components/jquery/dist/jquery.js");

var CityFacts = function CityFacts(language) {
    var requestDataFromApi;
    var onAjaxComplete;
    var fetchTemplates;
    var fetchAndRemoveTemplate;
    var buildLegend;
    var buildFamilyComposition;
    var buildNationalityComposition;
    var buildAgeDistribution;
    var buildTaxComparison;
    var buildMeanGrossIncome;
    var buildStatistic;
    var buildStatisticEntry;
    var buildStatisticRow;

    language = language || 'en';

    var legendTemplateSelector = '[data-legend-entry]';
    var statisticTemplateSelector = '[data-statistic]';
    var statisticContainerTemplateSelector = '[data-statistic-container]';
    var statisticRowTemplateSelector = '[data-statistic-row]';
    var statisticChartTemplateSelector = '[data-statistic-chart]';

    var $legendTemplate;
    var $statisticTemplate;
    var $statisticContainerTemplate;
    var $statisticRowTemplate;
    var $statisticChartTemplate;

    var $cityFactsContainer = $('[data-city-facts-container]');
    var host = location.protocol.concat('//').concat(window.location.hostname);

    var initialCity;
    var currentNumberFormatter;

    var percentFormatter = function(number) {
        return number.toFixed(1) + '%';
    };

    // Public method to get data from API and build the city facts
    this.buildCityFacts = function buildCityFacts() {

        // Not on the right page?
        if ($cityFactsContainer.length < 1) {
            return false;
        }

        var status = $cityFactsContainer.data('city-facts-container');

        // Already initialized? Nothing to do then
        if (status && status === 'initialized') {
            return true;
        }

        // Get initial city to show data for
        initialCity = $cityFactsContainer.data('city-facts-initial');

        if (!initialCity) {
            return false;
        }

        requestDataFromApi();
    };

    requestDataFromApi = function() {
        // TODO: allow user to input multiple cities (e.g, query=Bern,Zürich)

        var url = host + '/' + language + '/api/city_facts?query=' + initialCity;
        //var url = host + '/' + language + '/api/city_facts?query=Bern,Zürich,Basel,Genf';

        $.ajax(url, {
            data: null,
            complete: function(jqXHR) {
                onAjaxComplete(jqXHR.responseJSON);
            }
        });
    };

    onAjaxComplete = function(response) {
        if (!response) {
            return false;
        }

        var templatesLoaded = fetchTemplates();

        if (!templatesLoaded) {
            return false;
        }

        buildLegend(response.cities);
        buildFamilyComposition(response.familyComposition);
        buildNationalityComposition(response.nationalityComposition);
        buildAgeDistribution(response.ageDistribution);
        buildTaxComparison(response.taxComparison);
        buildMeanGrossIncome(response.meanGrossIncome);

        // All done
        // TODO: show (fade-in) city facets
        $cityFactsContainer.data('city-facts-container', 'initialized');

        return true;
    };

    fetchTemplates = function() {
        $legendTemplate = fetchAndRemoveTemplate(legendTemplateSelector);
        $statisticTemplate = fetchAndRemoveTemplate(statisticTemplateSelector);
        $statisticContainerTemplate = fetchAndRemoveTemplate(statisticContainerTemplateSelector);
        $statisticRowTemplate = fetchAndRemoveTemplate(statisticRowTemplateSelector);
        $statisticChartTemplate = fetchAndRemoveTemplate(statisticChartTemplateSelector);

        return !!($legendTemplate && $statisticTemplate && $statisticContainerTemplate && $statisticRowTemplate && $statisticChartTemplate);
    };

    fetchAndRemoveTemplate = function(selector) {
        var $template = $cityFactsContainer.find(selector);

        if ($template.length < 1) {
            return null;
        }

        var $newTemplate = $template.clone();

        $template.remove();

        return $newTemplate;
    };


    buildLegend = function(entries) {
        var $container = $('[data-city-legend]');

        if (!$legendTemplate || $container.length > 1 || !entries || entries.length < 1) {
            return false;
        }

        // Don't show legend if we only have one entry, it is self explanatory in this case.
        if (entries.length < 2) {
            $container.addClass('sr-only');
        }

        for (var i = 0; i < entries.length; i++) {
            var $entry = $legendTemplate.clone();
            $entry.find('[data-legend-label]').html(entries[i]);
            $entry.find('[data-legend-indicator]').addClass('city-facts__legend__entry__indicator--' + i);
            $entry.appendTo($container);
        }

        return true;
    };

    buildFamilyComposition = function(entries) {
        return buildStatistic(entries, $('[data-family-composition]'));
    };

    buildNationalityComposition = function(entries) {
        return buildStatistic(entries, $('[data-nationality-composition]'));
    };

    buildAgeDistribution = function(entries) {
        return buildStatistic(entries, $('[data-age-distribution]'));
    };

    buildTaxComparison = function(entries) {
        return buildStatistic(entries, $('[data-tax-comparison]'));
    };

    buildMeanGrossIncome = function(entries) {
        var formatter = function formatter(number) {
            number += ''; // String conversion
            var x = number.split('.'); // Split off decimals
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : ''; // Keep decimals
            var rgx = /(\d+)(\d{3})/;

            // Add ' for every 1000
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + '\'' + '$2');
            }

            return x1 + x2; // Re-attach decimals
        };

        return buildStatistic(entries, $('[data-mean-gross-income]'), formatter);
    };

    buildStatistic = function(entries, $container, numberFormatter) {

        if ($container.length > 1 || !entries || entries.length < 1) {
            return false;
        }

        // Either use given number formatter or standard one.
        currentNumberFormatter = numberFormatter || percentFormatter;

        var $entry = $statisticTemplate.clone();

        $entry.find('[data-statistic-label]').html(entries.title);
        delete entries.title; // No longer used

        // Normal case: one category per statistic, go directly to data
        if (entries.data) {
            buildStatisticEntry(entries.data, $entry.find('[data-statistics-container-wrapper]'));
        }

        // Special case: multiple categories per statistic (e.g. female & male)
        else {
            for (var key in entries) {
                if (entries.hasOwnProperty(key)) {
                    var value = entries[key];
                    var $statisticContainer = $statisticContainerTemplate.clone();

                    $statisticContainer.find('[data-statistic-container-label]').html(value.title);

                    $statisticContainer.appendTo($entry.find('[data-statistics-container-wrapper]'));

                    buildStatisticEntry(value.data, $statisticContainer);
                }
            }
        }

        $entry.appendTo($container);

        return true;
    };

    buildStatisticEntry = function(entries, $container) {

        for (var key in entries) {
            if (entries.hasOwnProperty(key)) {

                var $row = $statisticRowTemplate.clone();
                $row.find('[data-statistic-chart-label]').html(key);
                $row.appendTo($container);

                buildStatisticRow(entries[key], $row.find('[data-statistic-chart-container]'));
            }
        }
    };

    buildStatisticRow = function(entries, $container) {
        var biggestValue = 100;
        var i;

        // Need to find maximum value
        // E.g. for values of 111% and 150%, we must display the bar of 150% as 100%, and the one of 111% relatively smaller
        for (i = 0; i < entries.length; i++) {
            if (entries[i] > biggestValue) {
                biggestValue = entries[i];
            }
        }

        // Outputting
        for (i = 0; i < entries.length; i++) {
            var value = entries[i];
            var barWidth = value;
            var $chart = $statisticChartTemplate.clone();

            // Correction for large values
            if (biggestValue > 100) {
                barWidth = 100 / biggestValue * value;
            }

            $chart.find('[data-statistic-value]').html(currentNumberFormatter(value));
            $chart.find('[data-statistic-bar]').addClass('city-facts__statistic-bar--' + i);
            $chart.find('[data-statistic-bar-value]').css('width', barWidth + '%');
            $chart.appendTo($container);
        }
    };

    return this;
};

module.exports = CityFacts;
