/* global require */

'use strict';

// dependencies
var Library = {
    DOM: require('../lib/dom'),
    UI: require('../composites/ui')
};

/**
 * Details page module.
 *
 * @author Simo Jakovic <simo.jakovic@homegate.ch>
 * @return {DetailPage}
 */
var DetailPage = function () {

    // shortcuts
    var self = this;
    var DOM = Library.DOM;

    var onShowMap;
    var initSurroundingMap;

    /**
     * Public: Initialization logic of Bookmarks module.
     */
    self.init = function init() {
        // attach logic to bookmark buttons
        DOM.on('[data-show-map]', window.App.c.clickEvent, onShowMap);
    };

    initSurroundingMap = function() {
        var surroundingTab = DOM.select('[data-surroundings]');
        var fn = function(e) {

            window.App.fn.initMap();
            window.App.fn.initCityFacts();

            // Init only once
            DOM.off(e.currentTarget, 'click touchstart', fn);
        };

        DOM.on(surroundingTab, 'click touchstart', fn);
    };

    onShowMap = function (e) {

        var tabs = DOM.selectAll('[data-tab]');
        var tabContents = DOM.selectAll('[data-tab-content]');

        for (var i = 0; i < tabs.length; i++) {

            if (tabs[i].classList.contains('active')) {
                tabs[i].classList.remove('active');
                tabContents[i].classList.remove('active');
            }

            if (i === 1) {
                tabs[i].classList.add('active');
                tabContents[i].classList.add('active');
            }
        }

        window.App.fn.initMap();
        window.App.fn.initCityFacts();
    };

    return this;
};

module.exports = DetailPage;
