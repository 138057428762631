/* global require */

'use strict';

// Dependencies
var $ = require("./../../../bower_components/jquery/dist/jquery.js");
var Forms = require('../lib/forms');
require('autocomplete');

var Search = function Search(apiBaseUrl, language) {
    var attachHiddenSearchInfoToForm;

    // Defaults
    apiBaseUrl = apiBaseUrl || 'https://www.homegate.ch/rs';
    language = language || 'de';

    // Vars
    var currentSuperCategory = 'rent';
    var defaultChooseTypes = {
        'rent': 'rentflat',
        'buy': 'purchfaofh'
    };
    var apiUrl = '/api-search.php';
    var apiPub = 'home';
    var autocompleteField = $('[data-search-autocomplete]');
    var $searchForm = $('#search-form');
    var $filterForm = $('#filter-form');
    var $mainForm = $searchForm;
    var returnValues = 'name,type,count,typeLabel,urlNames';

    if ($filterForm.length > 0) {
        $mainForm = $filterForm;
    }

    var defaultSearchParams = {'lang': language};

    var isFrontPage = $searchForm === $mainForm;

    if (isFrontPage) {
        defaultSearchParams.cht = defaultChooseTypes[currentSuperCategory];
    }

    var searchParams = defaultSearchParams;

    var submitForm = function submitForm($form) {
        $form.prop('target', ''); // Make sure we always open form in the same window

        // save state of filters panel/extended search
        window.localStorage.setItem('filtersOpen',
            document.body.classList.contains('offscreen--show')
        );

        $form.submit();
    };

    var initAutocomplete = function initAutocomplete() {

        // Check, if there are other parameters set than the default parameters defined above. If so, merge the parameters.
        // This is used so the current search parameters defined by the filter are also respected when searching for a new
        // location. The searchParameters are set in search-results.twig, and are not used on the front page.
        if (window.appConfig && window.appConfig.searchParameters) {
            searchParams = window.appConfig.searchParameters;
            for (var attributeName in defaultSearchParams) {
                if (defaultSearchParams.hasOwnProperty(attributeName)) {
                    searchParams[attributeName] = defaultSearchParams[attributeName];
                }
            }
        }

        autocompleteField.autocomplete({
            serviceUrl: apiUrl,
            paramName: 'name',
            params: searchParams,
            dataType: 'json',
            minChars: 3,
            preserveInput: false,
            transformResult: function (response) {
                return {
                    suggestions: $.map(response.results, function (dataItem) {
                        var urlNameSplit = dataItem.geoLocation.urlNames[language].split('-'),
                            typeLabel = urlNameSplit[0];

                        return {
                            value: dataItem.geoLocation.names[language][0],
                            data: dataItem.geoLocation.urlNames[language],
                            name: dataItem.geoLocation.names[language][0],
                            type: dataItem.geoLocation.type,
                            typeLabel: typeLabel,
                            id: dataItem.geoLocation.id,
                            raw: dataItem
                        };
                    })
                };
            },
            formatResult: function (suggestion) {
                var html = '<span class="location">' + suggestion.name + '</span>&nbsp;';
                html += '<span class="locationType">' + suggestion.typeLabel + '</span>';

                return html;
            },
            onSelect: function (suggestion) {
                var urlNames = [];
                $.each(suggestion.raw.geoLocation.urlNames, function(i, el) {
                    var t = {};
                    t.language = i;
                    t.name = el.split('-').slice(1).join('-');
                    urlNames.push(t);
                });
                attachHiddenSearchInfoToForm($mainForm,
                    suggestion.type,
                    suggestion.typeLabel,
                    JSON.stringify(urlNames),
                    suggestion.name,
                    suggestion.id);

                $mainForm.find('input[name="suggestion-selected"]').val(true);
                submitForm($searchForm);
            }
        });
    };

    var initSearchAndFilterForm = function initSearchAndFilterForm() {

        // When chaning the category and a subcategory is selected, reset the subcategory
        $('select[name="category"]').change(function () {
            $('select[name="subcategory"]').val('');
        });

        // Auto submit magic
        var $autoSubmitters = $filterForm.find('[data-submit-on-change]');
        $autoSubmitters.change(function () {
            if ($(this).data('submit-on-change') === true) {
                submitForm($(this).parents('form'));
            }
        });

        // handle submitting state for forms
        $filterForm.on('submit', function (e) {
            Forms.submit(this);
        });


        // Submit magic
        $searchForm.submit(function (e) {
            var inputVal = $(this).find('input[name="search_query"]').val();

            // Do nothing when user entered nothing
            if (!inputVal || (inputVal.length && inputVal.length < 1)) {
                e.preventDefault();
                return false;
            }

            // add submitting state
            Forms.submit($searchForm[0]);

            // If we are on the search page we also have to attach the search query to the filter form
            if ($mainForm === $filterForm) {
                $mainForm.find('input[name="search_query"]').val(inputVal);
            }

            // If a user directly clicked on a suggestion, we already have all the information we need.
            var suggestionSelected = $mainForm.find('input[name="suggestion-selected"]').val();
            if (suggestionSelected === 'true') {

                // If we are on the search page, submit the filter form instead of the search form
                if ($mainForm === $filterForm) {
                    submitForm($mainForm);
                    e.preventDefault();
                }

                return true;
            }

            var autocomplete = autocompleteField.data('autocomplete');

            var inputValLowerCase = inputVal.toLowerCase();
            var result = inputVal;
            var fullTextSearch = true;

            /*
             1. Take the suggestion which is written the same as the given string, if suggestions are available
             2. Take the first suggestions from the list, if suggestions are available
             3. Consider the given string as a full-text search-query
             */
            if (autocomplete) {
                var suggestions = autocomplete.suggestions;
                var i;

                for (i = 0; i < suggestions.length; i++) {
                    var suggestedName = suggestions[i].raw.geoLocation.hg5[language].toLowerCase();
                    if (i === 0) {
                        result = suggestions[i];
                        fullTextSearch = false;

                        if (inputValLowerCase === suggestedName) {
                            break;
                        }
                    }
                    else if (inputValLowerCase === suggestedName) {
                        result = suggestions[i];
                        fullTextSearch = false;
                        break;
                    }
                }
            }

            // If we take some suggestion from the autocomplete form, fill the main form with the result
            // Else we are interpreting the entered value as full-text search
            if (!fullTextSearch) {
                var urlNames = [];
                $.each(result.raw.geoLocation.urlNames, function(i, el) {
                    var t = {};
                    t.language = i;
                    t.name = el.split('-').slice(1).join('-');
                    urlNames.push(t);
                });

                attachHiddenSearchInfoToForm($mainForm,
                    result.type,
                    result.typeLabel,
                    JSON.stringify(urlNames),
                    result.name,
                    result.id);
            }
            // Fulltext or zip search?
            else {
                var fourDigits = /^\d{4}$/;
                if (fourDigits.test(result)) {
                    $mainForm.find('input[name="postal_code"]').val(result);
                } else {
                    e.preventDefault();
                    $mainForm.find('button[type="submit"]').removeClass('submit');
                    $mainForm.find('button[type="submit"]').attr('disabled', false);
                    $mainForm.find('input[name="fulltext"]').val(result);
                }
            }

            // If we are on the search page, submit the filter form instead of the search form
            if ($mainForm === $filterForm) {
                submitForm($mainForm);
                e.preventDefault();
            }
        });
    };

    attachHiddenSearchInfoToForm = function ($form, type, typeLabel, urlNames, name, id) {
        $form.find('input[name="locationType"]').val(type);
        $form.find('input[name="locationTypeLabel"]').val(typeLabel);
        $form.find('input[name="locationUrlNames"]').val(urlNames);
        $form.find('input[name="locationName"]').val(name);
        $form.find('input[name="locationId"]').val(id);

        // If user has entered a zip manually, reset postal code search from before
        if (type === 'zip') {
            $form.find('input[name="postal_code"]').val('');
        }
    };

    var initSuperCategorySwitch = function initSuperCategorySwitch() {
        $searchForm.submit(function () {
            var action = $searchForm.attr('action');
            var $switcher = $('[data-super-category-switch]');
            var $activeSuperCategory = $switcher.find('.active');

            if ($activeSuperCategory.length < 1) {
                return;
            }

            action = action.substring(0, action.lastIndexOf('/') + 1);
            currentSuperCategory = $activeSuperCategory.data('super-category');
            defaultSearchParams.cht = defaultChooseTypes[currentSuperCategory];

            action += currentSuperCategory;
            $searchForm.attr('action', action);
        });

        $('[data-super-category]').click(function (e) {
            e.preventDefault();

            var $link = $(this);
            currentSuperCategory = $link.data('super-category');
            defaultSearchParams.cht = defaultChooseTypes[currentSuperCategory];

            $link.addClass('active');

            if (currentSuperCategory === 'rent') {
                $('[data-super-category="buy"]').removeClass('active');
            }
            else {
                $('[data-super-category="rent"]').removeClass('active');
            }

            // Switch autocomplete search category
            autocompleteField.autocomplete('setOptions', {params: searchParams});
        });

        $('.search-results__category').click(function (e) {

            var link = $(this).attr('href');
            var linkParts = link.split('/');
            var newLinkParts = [];

            e.preventDefault();

            for (var i = 0; i < linkParts.length; i++) {

                var a = linkParts[i].indexOf('price-') === -1;
                var b = linkParts[i].indexOf('prezzo-') === -1;
                var c = linkParts[i].indexOf('prix-') === -1;
                var d = linkParts[i].indexOf('preis-') === -1;

                if(a && b && c && d) {
                    newLinkParts.push(linkParts[i]);
                }
            }

            link = newLinkParts.join('/');

            window.location.href = link;
        });

    };

    this.init = function init() {
        initSuperCategorySwitch();
        initAutocomplete();
        initSearchAndFilterForm();

        return this;
    };

    return this;
};

module.exports = Search;
