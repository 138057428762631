/* global DocumentTouch */

'use strict';

// Named depencencies
var $ = require("./../../bower_components/jquery/dist/jquery.js");
var UI = require('./composites/ui');
var Search = require('./modules/search');
var Subscription = require('./modules/subscription');
var Contact = require('./modules/contact');
var Bookmarks = require('./modules/bookmarks');
var Maps = require('./modules/maps');
var CityFacts = require('./modules/cityfacts');
var NumberTracking = require('./modules/numberTracking');
var Tamedia = require('./modules/tamedia');
var DetailPage = require('./modules/detailPage');
// var PrivacyModal = require('./modules/privacyModal.js');

// Making console calls safe
window.console = window.console || {};
window.console.log = window.console.log || function() {};

/**
 * Home.ch App
 *
 * @returns {App}
 * @constructor
 */
var App = function App(debug) {

    debug = debug || false;

    // Shortcut
    var self = this;

    /**
     * Public application functions.
     *
     * @type {Object}
     */
    this.fn = {};

    /**
     * Public application configuration.
     *
     * @type {Object}
     */
    this.c = {
        apiBaseUrl: window.appConfig.apiBaseUrl || 'https://www.homegate.ch',
        language: window.appConfig.language || 'de',
        clickEvent: 'click'
    };

    /**
     * Public application events.
     *
     * @type {Object}
     */
    this.e = {};

    /**
     * Public UI API.
     *
     * @type {UI}
     */
    this.ui = new UI();

    /**
     * Provide a initialization function for the application.
     *
     * @returns {App}
     */
    this.fn.init = function init() {

        // Private modules
        // new PrivacyModal().init();
        new Bookmarks().init();
        new Search(self.c.apiBaseUrl, self.c.language).init();
        new Subscription(self.c.language).init();
        new Contact(self.c.language).init();
        new NumberTracking(self.c.language).init();
        new Tamedia().init();
        new DetailPage().init();

        if (debug) {
            console.log('Home.ch application initialized.');
        }

        return this;
    };

    /**
     * Initializes city facts
     */
    this.fn.initCityFacts = function initCityFacts() {
        var cityFacts = new CityFacts(self.c.language);
        cityFacts.buildCityFacts();
    };

    /**
     * Initializes a (Google) map.
     */
    this.fn.initMap = function initMap() {
        new Maps('google');
    };

    return this;
};

// Starting the app...
$(function() {

    // Set debug to false on production
    window.App = new App(false);

    // Init app
    window.App.fn.init();

    // Init UI
    window.App.ui.init();
});
