/* global require */

'use strict';

var $ = require("./../../../bower_components/jquery/dist/jquery.js");

var NumberTracking = function NumberTracking(language) {

    language = language || 'de';

    var adId;
    var baseUrl;

    var track = function track($element) {
        var channel = $element.data('numberTracking-channel');

        var breakpoint = window.getComputedStyle(document.querySelector('body'), ':after').getPropertyValue('content').replace(/"/g,'');

        if ((breakpoint === 'mobile' && channel !== 'mobile') ||
          breakpoint !== 'mobile' && channel === 'mobile')
        {
            return false;
        }

        var numberType = $element.data('numberTracking-numberType');
        var number = $element.data('numberTracking-number');
        var url = baseUrl + '?contact_type=' + numberType + '&contact_value=' + encodeURIComponent(number) + '&lang=' + language;

        $.ajax(url, {}).always(function(data) {
            // Only needed for debugging purposes
            // if (data.status && data.status === 'success') {
            //     console.log('tracked!');
            // } else {
            //     console.log('not tracked!');
            // }
        });
    };

    var onClickOnField = function onClickOnField() {
        track($(this));
    };

    var initField = function(element) {

        var $element = $(element);
        var rawData = $element.data('track-number');

        if (!rawData || !rawData.length) {
            return null;
        }

        rawData = rawData.split(',');

        if (rawData.length < 2) {
            return null;
        }

        var numberType = rawData[0];
        var number = rawData[1];
        var channel = rawData[2] ? rawData[2] : false;

        $element.data('numberTracking-numberType', numberType);
        $element.data('numberTracking-number', number);
        $element.data('numberTracking-channel', channel);
        $element.one('click', onClickOnField);
    };

    this.init = function init() {
        adId = $('[data-ad-id]').data('ad-id');

        if (!adId) {
            return this;
        }

        baseUrl = '/'+language+'/contact-inquiry/' + adId;

        var $fieldsToTrack = $('[data-track-number]');

        if (!$fieldsToTrack || $fieldsToTrack.length < 0) {
            return this;
        }

        $fieldsToTrack.each(function() {
            initField(this);
        });
    };


    return this;
};

module.exports = NumberTracking;
