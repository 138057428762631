/* global require */

'use strict';

// dependencies
var Library = {
    DOM: require('../lib/dom'),
    GMaps: require('../lib/gmaps')
};

/**
 * Maps module.
 *
 * @author Sebastian Prein <basti@gridonic.ch>
 * @param {Object} vendor Vendor map provider.
 * @param {Object} options Options to change module behaviour.
 * @return {Maps}
 */
var Maps = function(vendor, options) {

    // shortcuts
    var self = this;
    var DOM = Library.DOM;

    // public variables
    self.version = '0.1.0';

    // private variables
    var container;
    var defaults = {
        container: '[data-map]',
        legend: '[data-map-legend]',
        legendItems: '[data-map-legend-item]',
        legendSelect: '[data-map-legend-select]',
        legendActiveClass: 'active',
        mapOptions: {
            zoom: 13,
            scrollwheel: true
        },
        poi: {
            radius: 3000,
            types: [],
            scaleControl: true
        }
    };
    var icons = [];
    var legend;
    var legendItems;
    var legendSelect;
    var map;
    var settings = {};
    var supports = [ 'google' ];
    var types = [];

    // private methods
    var initLegend;
    var onLegendItemClick;
    var onLegendSelectChange;
    var search;
    var updateLegendItems;
    var updateSelect;

    /**
     * Initializes the legend.
     */
    initLegend = function() {

        //WX-538
        // // get legend
        // if (typeof settings.legend === 'string') {
        //     legend = DOM.select(settings.legend);
        // } else {
        //     legend = settings.legend;
        // }
        //
        // // container not found
        // if (container === null) {
        //     return console.warn('Maps legend not found.');
        // }
        //
        // // grab all legend items and selection
        // legendItems = DOM.selectAll(settings.legendItems);
        // legendSelect = DOM.select(settings.legendSelect);
        //
        // // grab icons and types
        // for (var i = 0; i < legendItems.length; i++) {
        //     icons.push(DOM.data(legendItems[i], 'place-icon'));
        //     types.push(JSON.parse(DOM.data(legendItems[i], 'place-type')));
        // }
        //
        // // attach action handlers
        // DOM.on(legendItems, 'click touchstart', onLegendItemClick);
        // DOM.on(legendSelect, 'change', onLegendSelectChange);
        //
        // // initial search by active element
        // var active = DOM.select('.' + settings.legendActiveClass, legend) || legendItems[0];
        //
        // // abuse event listener
        // onLegendItemClick({ currentTarget: active });
    };

    /**
     * A legend item was clicked.
     *
     * @param {Event} e Original event.
     */
    onLegendItemClick = function(e) {
        var index = Array.prototype.indexOf.call(legendItems, e.currentTarget);

        // update legend items
        updateLegendItems(index);

        // updateSelect
        updateSelect(index);

        // start the search
        search({
            types: types[index],
            icon: icons[index]
        });
    };

    /**
     * The legend selection has been changed.
     *
     * @param {Event} e Original event.
     */
    onLegendSelectChange = function(e) {
        var index = e.target.selectedIndex;

        // update legend items
        updateLegendItems(index);

        // start the search
        search({
            types: types[index],
            icon: icons[index]
        });
    };

    /**
     * Updates the current item that works as an active indicator for the
     * selection list.
     *
     * @param {Number} index The index of the current selected item.
     */
    updateSelect = function(index) {
        var selectedItem = legendSelect.children[index];

        // set selection to correct options
        legendSelect.selectedIndex = index;
    };

    /**
     * Updates the legend items.
     *
     * @param {Number} index The index of the active item.
     */
    updateLegendItems = function(index) {

        // remove active states
        for (var i = 0; i < legendItems.length; i++) {
            legendItems[i].classList.remove(settings.legendActiveClass);
        }

        // add active state to active index
        legendItems[index].classList.add(settings.legendActiveClass);
    };

    /**
     * Searches the map by a given request for nearby locations.
     *
     * @param {String} request What to look for.
     */
    search = function(request) {

        // no or invalid data to search for
        if (request === null || typeof request !== 'object' || Array.isArray(request.types) === false) {
            return;
        }

        map.setZoom(defaults.mapOptions.zoom);

        // clear old markers
        map.clearMarkers();

        // search for nearby points of interest
        map.searchNearby({
            location: map.getCenter(),
            radius: settings.poi.radius,
            types: request.types,
            // bounds: map.getBounds(),
            // location: map.getCenter()

        // add them to the map
        }, function(results) {

            // apply custom icons
            if (request.icon) {
                for (var i = 0; i < results.length; i++) {
                    results[i].icon = request.icon;
                    results[i].name = results[i].name + '<br>' + results[i].vicinity;
                    results[i].visible = ( i < 1 ) ? true : false;
                }
            }

            map.addMarkers(results);
        });

    };

    /**
     * Returns the container of the map module.
     *
     * @return {Element}
     */
    self.getContainer = function() {
        return container;
    };

    /**
     * Initialization logic of Maps module.
     */
    (function() {

        options =  options || {};

        // merge new settings into current ones
        for (var key in defaults) {
            if (options.hasOwnProperty(key)) {
                settings[key] = options[key];
            } else {
                settings[key] = defaults[key];
            }
        }

        // unsupported map provider
        if (supports.indexOf(vendor) < 0) {
            return console.warn('Maps module doesn\'t support maps from a vendor called \'' + vendor + '\'.');
        }

        // get container
        if (typeof settings.container === 'string') {
            container = DOM.select(settings.container);
        } else {
            container = settings.container;
        }

        // container not found
        if (container === null) {
            return console.warn('Maps container not found.');
        }

        // init google maps
        if (vendor.toLowerCase() === 'google' && 'google' in window) {

            // create instance of GMaps
            map = new Library.GMaps(window.google, {
                container: container,
                mapOptions: settings.mapOptions
            });
        }

        // use location provided by container
        map.setCenter(JSON.parse(DOM.data(container, 'location')));

        // provided center of the map is inaccurate, no pin will be shown
        var locationInaccurate = !!JSON.parse(DOM.data(container, 'location-inaccurate'));

        // add persistent marker for advertisement location
        if (locationInaccurate === false) {
            map.addMarker({
                position: map.getCenter(),
                icon: DOM.data(container, 'icon'),
                persistent: true
            });
        }
        else {
            map.addMarker({
                position: map.getCenter(),
                icon: DOM.data(container, 'icon'),
                persistent: true
            });
        }

        // initialize legend
        initLegend();

    })();
};

module.exports = Maps;
